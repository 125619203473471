<template>
  <v-app class="transparent">
    <v-container
      id="notification-change-request"
      fluid
      tag="section">
      <VValidationErrors :validation-errors="validationErrors"></VValidationErrors>
      <base-material-card
        icon="mdi-bell-plus-outline"
        title="Create Notification Change Request"
        class="px-5 py-3">
        <v-form
          ref="form"
          on-submit="return false;"
          @submit="submit"
        >
          <v-container class="py-0">
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="notificationData.notification_text"
                  :rules="rules.notification_text"
                  label="Notification Text"
                  class="purple-input"
                  required
                  outlined
                />
              </v-col>
              <v-col
                cols="12">
                <v-btn
                  color="success"
                  class="mr-0"
                  @click="submit">
                  Create Change Request
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </base-material-card>
    </v-container>
  </v-app>
</template>
<script>
  import axios from 'axios'
  import Vue from 'vue'
  import VValidationErrors from '../../components/base/VValidationErrors'
  export default {
    components: { VValidationErrors },
    data: () => ({
      durations : [],
      notificationData: {
        notification_text: '',
        notification_order: '',
        notification_id: '',
        duration_id: '',
      },
      rules: {
        notification_text: [
          v => !!v || 'Notification text is required',
        ],
      },
      validationErrors: {},
    }),
    created () {
      this.getNotificationData()
    },
    methods: {
      getNotificationData() {
        this.$authApi.get(`/partner-notifications/${ this.$route.params.notification_id }`).then(res => {
          //this.userData = res.data.data.username;
          this.notificationData = {
            notification_text: res.data.data.notification_text,
            notification_order: res.data.data.notification_order,
            notification_id: res.data.data.notification_id,
            duration_id: res.data.data.duration_id,
          }
        });

      },
      submit () {
        if (!this.$refs.form.validate()) {
          return
        }
        this.addChangeRequest()
      },
      addChangeRequest () {
        this.$authApi.post('/notification-change-requests', this.notificationData)
          .then(res => {
            this.flashMessage.success({
              title: 'Created  Successfully',
              message: `Your notification change request has been created successfully`,
            })
            this.$router.push(`/notifications/change-requests/${this.$route.params.notification_id}`)
          }).catch(error => {
          if (error.response) {
            this.validationErrors = error.response.data.errors;
          }
        })
      },
    },
  }
</script>
